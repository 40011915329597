import { Bankrec } from '@mca/user/api';
import { HolidaysService } from '@mca/references/api';
import { Transactions } from './trans-rec';
import { McaTransactionService } from '../infrastructure/mca-transaction.service';

export const adjustDate = (shed: Transactions[]) => {
  shed.forEach((r: Transactions) => {
    r.effectivedate = Transactions.convertEffectiveDate(r.effectivedate);
    r.ammount = +(r.ammount ?? 0);
    r.accttype = +((r as any).accountType ?? r.accttype) || 1;
  });
};

// unused
export const adjustTransactionsDates = (
  holidaysService: HolidaysService,
  mcaSvcs: McaTransactionService,
  mcaid: number,
  trType: number,
  trStat: number,
  oldStart: Date,
  newStart: Date,
) =>
  mcaSvcs.getTransactionList({ mcaid, transtype: trType, status: trStat }).subscribe(res => {
    const delta = holidaysService.businessDiff(oldStart, newStart);
    const sched = res as Transactions[];
    sched.forEach(tr => (tr.effectivedate = holidaysService.addBusinessDays(tr.effectivedate, newStart >= oldStart ? delta : -delta)));
    return mcaSvcs.createTransactionsBulk(sched);
  });

export const paymentTransFactory = (id: number, transType: number, mca: { id: number }, bankAccount?: Bankrec): Transactions => {
  const tr = new Transactions();
  tr.mcaid = mca.id;
  tr.id = id;
  tr.transtype = transType;

  if (bankAccount) {
    tr.acctname = bankAccount.name || '';
    tr.account = bankAccount.account || '';
    tr.routing = bankAccount.routing || '';
    tr.accttype = bankAccount.accounttype || 1;
  }
  return tr;
};
