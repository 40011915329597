export class PaymentCreateRequestSchema {
  transCount = 0;
  paymentMethod = 'ACH';
  comment = '';
  initialDate = new Date();
  initialAmount = 0;
  recurringFirstDate = new Date();
  recurringAmount = 0;
  recurringFrequency = 'Daily';
  externTransId = '';
  settlementBalance?: number;
  requestNewSettlementDiscount = false;
}

export class PaymentCreateRequestInfo {
  currentBalance = 0;
  pendingPayments = 0;
  preparedPayments = 0;
  sentAndScheduledPayments = 0;
  minDate: Date = new Date();
}

export class PaymentModifyRequestSchema {
  holdAllPayments? = false;
  holdStartDate?: Date | null = null;
  holdEndDate?: Date | null = null;
  startDate = new Date();
  numOfPayments = 0;
  amount = 0;
  note = '';
  useContractRate = false;

  constructor(public frequency: number) {}
}
