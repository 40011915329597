import { Injectable, computed, inject, signal } from '@angular/core';
import { catchError, EMPTY, filter, map, switchMap } from 'rxjs';
import { TransStatusAcro, TransSubTypeAcro, TransTypeAcro } from '../entities/trans-const';
import { TransRec, TransAssignmentType } from '@mca/shared/domain';
import { McaTransactionService } from '../infrastructure/mca-transaction.service';
import { AppConstantsService } from '@mca/shared/domain';
import { LoanService, LoanStatus } from '@mca/loan/domain';
import { roundMoney } from '@mca/shared/util';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';

@Injectable()
export class TransactionActivateService {
  private mcaTransService = inject(McaTransactionService);
  private appConstantsService = inject(AppConstantsService);
  private loanService = inject(LoanService);

  transaction = signal<TransRec | undefined>(undefined);
  isMerchant = computed(() => {
    const transaction = this.transaction();
    return !!(
      transaction &&
      transaction.id > 0 &&
      transaction.mcaid > 0 &&
      transaction.userid === 0 &&
      transaction.status === TransStatusAcro.prep &&
      (transaction.transtype === TransTypeAcro.dtm ||
        transaction.transtype === TransTypeAcro.dcon ||
        transaction.transtype === TransTypeAcro.dto ||
        transaction.transtype === TransTypeAcro.refund)
    );
  });
  isLender = computed(() => {
    const transaction = this.transaction();
    return (
      transaction &&
      transaction.id > 0 &&
      transaction.status === TransStatusAcro.prep &&
      transaction.transtype === TransTypeAcro.deposit &&
      transaction.userid > 0
    );
  });
  isContractFee = computed(() => {
    const transaction = this.transaction();
    return (
      transaction &&
      transaction.transtype === TransTypeAcro.cfee &&
      transaction.status === TransStatusAcro.prep &&
      transaction.id > 0 &&
      transaction.mcaid > 0 &&
      transaction.userid === 0
    );
  });
  isBPWithdrawal = computed(() => {
    const transaction = this.transaction();
    return (
      transaction &&
      (transaction.transtype === TransTypeAcro.withdrawal || transaction.transtype === TransTypeAcro.deposit) &&
      transaction.status === TransStatusAcro.hold &&
      this.appConstantsService.isBasepointCollection(transaction.dealvenue)
    );
  });
  isRefund = computed(() => this.isMerchant() && this.transaction()?.transtype === TransTypeAcro.refund);
  isLoanTransaction = computed(
    () => this.transaction()?.transtype === TransTypeAcro.dtm && this.transaction()?.transsubtype === TransSubTypeAcro.loan,
  );
  canActivate = computed(() => this.isMerchant() || this.isLender() || this.isContractFee());
  canActivateLoan = computed(() => this.isLoanTransaction() && this.loan()?.status === LoanStatus.New);
  activateBtnText = computed(() => {
    if (this.isMerchant()) {
      return 'Activate Merchant Dep';
    } else if (this.isLender()) {
      return 'Activate Lender Dep';
    } else if (this.isContractFee()) {
      return 'Activate Contract Fee';
    }
    return '';
  });
  contractFee = toSignal<number>(
    toObservable(this.isMerchant).pipe(
      filter(isMerchant => isMerchant),
      switchMap(() =>
        this.mcaTransService.getTransactionContracFee(this.transaction()?.mcaid ?? 0, this.transaction()?.id ?? 0).pipe(
          map(fee => roundMoney(fee)),
          catchError(() => EMPTY),
        ),
      ),
    ),
  );
  private hasContractLoan = computed(() => {
    const transaction = this.transaction();
    if (
      transaction &&
      this.isLoanTransaction() &&
      transaction.assigned &&
      transaction.assigned.ref_id &&
      transaction.assigned.ref_name === TransAssignmentType.contractloan
    ) {
      return true;
    }
    return false;
  });
  loan = toSignal(
    toObservable(this.hasContractLoan).pipe(
      filter(hasContractLoan => hasContractLoan),
      switchMap(() => this.loanService.getLoan(this.transaction()?.assigned?.ref_id ?? 0).pipe(catchError(() => EMPTY))),
    ),
  );

  setTransaction(transaction: TransRec) {
    this.transaction.set(transaction);
  }

  activate(activateStatus: number, contractFee: number) {
    const transaction = this.transaction();
    if (!transaction) {
      return;
    }
    if (this.isContractFee()) {
      return this.mcaTransService.activateContractFee(transaction.mcaid, transaction.id);
    }

    let depType = '';
    let mcaId = 0;
    if (this.isMerchant()) {
      depType = this.isRefund() ? 'mcarefund' : 'merchant';
      mcaId = transaction.mcaid;
    }
    if (this.isLender()) {
      depType = 'lender';
    }

    if (!depType) {
      throw new Error('Unexpected deposit type');
    }
    if (activateStatus === TransStatusAcro.sched) {
      transaction.venue = 'ACH';
    } else if (!transaction.venue) {
      transaction.venue = 'wire';
    }

    return this.mcaTransService.activateDeposit(mcaId, transaction.id, depType, activateStatus, transaction.venue, contractFee);
  }

  activateLoan(loanId: number, transId: number) {
    return this.loanService.activateLoan(loanId, transId);
  }
}
